<template>
  <div class="page-box">
    <a-spin :spinning="loading">
      <a-form-model
        style="width:900px;"
        ref="formRef"
        :model="queryRef"
        @submit.native.prevent
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :rules="rules"
      >
        <a-form-model-item label="角色名称" prop="roleName">
          <a-input
            allowClear
            v-model="queryRef.roleName"
            placeholder="请输入"
            :disabled="type ==='2'"
          />
        </a-form-model-item>
        <a-form-model-item label="角色描述" prop="roleDesc">
          <a-input
            v-model="queryRef.roleDesc"
            allowClear
            :autoSize="{minRows:4}"
            placeholder="请输入"
            type="textarea"
            :disabled="type ==='2'"
          />
        </a-form-model-item>
        <a-form-model-item label="权限设置" required>
          <a-row class="auth-row-box">
            <a-col class='auth-col-box' :span="8" style="border-right:1px solid #ddd;">
              <p class="auth-box-title">菜单权限</p>
              <a-tree
                  v-if="treeData.length"
                  @check="checkTree"
                  showIcon
                  :defaultExpandAll="false"
                  :replaceFields="replaceFields"
                  v-model="checkedKeys"
                  :checkable="type !=='2'"
                  :tree-data="treeData"
                  @select="clickTreeNode">
                  <template slot="page" slot-scope="row">
                    <icon-svg :icon-class="row.icon||'icon-page'" />
                  </template>
                  <template slot="btn">
                    <icon-svg icon-class="icon-btn" />
                  </template>
                </a-tree>
            </a-col>
            <a-col class='auth-col-box' :span="16">
              <div class="auth-box-title">
                <p>按钮权限</p>
                <a-checkbox v-if="btnsList.length" :disabled='type==="2"' :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
                  全选
                </a-checkbox>
              </div>
              <a-spin :spinning='btnListLoading'>
                <p class='no-data-text' v-if="btnsList.length === 0 &&!btnListLoading">暂无数据</p>
                <a-checkbox-group :disabled='type==="2"' v-model="checkedList" style='width:100%' @change="checkBoxChange">
                  <div v-for='btn in btnsList' :key='btn.mmsFuncId' class='btn-item'>
                    <a-checkbox @change="checkBtnItem" :value="btn.mmsFuncId">{{btn.funcName}}</a-checkbox>
                  </div>
                </a-checkbox-group>
              </a-spin>
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-row v-if="type !=='2'" :gutter="24" type="flex" align="middle" justify="center">
          <a-col>
            <a-button style='margin-top:8px' size="large" type="primary" @click.native="handleSave" :loading="btnLoading">保存</a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </a-spin>
  </div>
</template>
<script>
import { ref, reactive, toRefs } from '@vue/composition-api'
import { system, staff } from '@/api'
import { useFetch } from '@/hooks'
import { SHOPROLEID, SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import router from '@/router'
export default {
  setup (props, { root }) {
    const labelCol = { span: 3 }
    const wrapperCol = { span: 19 }
    const rules = {
      roleName: [
        { required: true, message: '请输入角色名称', trigger: 'blur' },
        { max: 32, message: '您可以尝试一下简短的角色名称', trigger: 'blur' }
      ],
      roleDesc: [
        { max: 128, message: '您可以尝试一下简短的角色描述', trigger: 'blur' }
      ]
    }
    const replaceFields = { children: 'children', title: 'name', key: 'id' }

    const state = reactive({
      queryRef: {
        roleName: '',
        roleDesc: ''
      },
      checkedKeys: [],
      type: root.$route.params.type || '0',
      id: root.$route.params.id || '',
      lastFuncIds: [],
      lastBtnIds: [],
      btnLoading: false,
      btnListLoading: false,
      pSelect: [],
      formRef: null,
      btnsList: [],
      checkedList: [],
      indeterminate: false,
      checkAll: false,
      unChecked: [],
      checked: []
    })
    const { loading, data: treeData } = useFetch(
      () =>
        system.getAllMenus({
          shopRoleId: state.type === '2' ? state.id : '',
          flag: 1
        }).then(res => {
          handleIconSoltDeep(res.data)
          if (state.type !== '0' && state.id) {
            getRoleData()
          }
          return res.data
        }),
      [],
      ref(0)
    )
    async function getRoleData () {
      let { code, data } = await staff.getRoleDetail({
        shopRoleId: state.id
      })
      if (code === '00000') {
        let leafData = convertTreeListToKeyLeafPairs(treeData.value)
        state.pSelect = data.funcMenuIds.filter(v => {
          if (leafData.indexOf(v) === -1) {
            return v
          }
        })
        state.lastBtnIds = data.funcButtonIds
        state.lastFuncIds = data.funcMenuIds
        state.checkedKeys = data.funcMenuIds.filter(v => {
          if (leafData.indexOf(v) !== -1) {
            return v
          }
        })
        state.queryRef.roleName = data.roleName
        state.queryRef.roleDesc = data.roleDesc
      }
    }
    function convertTreeListToKeyLeafPairs (treeList, keyLeafPair = []) {
      for (const { id, children } of treeList) {
        if (children && children.length > 0) {
          convertTreeListToKeyLeafPairs(children, keyLeafPair)
        } else {
          keyLeafPair.push(id)
        }
      }
      return keyLeafPair
    }
    function handleSave () {
      state.formRef.validate(async valid => {
        if (valid) {
          let param = {
            shopId: getSession(SHOPID),
            roleName: state.queryRef.roleName,
            roleDesc: state.queryRef.roleDesc,
            funcMenuIds: state.checkedKeys.concat(state.pSelect).join(','),
            unChecked: state.unChecked.filter(item => state.lastBtnIds.indexOf(item) !== -1).join(','),
            checked: state.checked.filter(item => state.lastBtnIds.indexOf(item) === -1).join(',')
          }
          if (state.type === '1') {
            param.shopRoleId = state.id
            param.lastFuncIds = state.lastFuncIds.join(',')
          }
          state.btnLoading = true
          let { code } = await staff.saveRole(param)
          state.btnLoading = false
          if (code === '00000') {
            root.$message.success(
              state.type === '0' ? '创建成功' : '修改成功',
              1,
              async () => {
                // 如果 修改的是当前所登录的账号权限  需要重新请求权限
                if (param.shopRoleId === getSession(SHOPROLEID)) {
                  await root.$store.dispatch('route/initRoutes')
                  router.addRoutes(root.$store.state.route.routes)
                }
                root.$bus.$emit('PageRoleList:refresh')
                // getRoleData()
                if (
                  root.$store.state.tabs.views
                    .map(item => item.path)
                    .indexOf('/system/role_management') !== -1
                ) {
                  root.$closeCurrentView()
                } else {
                  root.$closeCurrentView('/system/role_management')
                }
              }
            )
          }
        } else {
          return false
        }
      })
    }

    function handleIconSoltDeep (arr) {
      arr.forEach(item => {
        if (!item.icon) {
          item.icon = ''
        }
        item.scopedSlots = { icon: item.type === 1 ? 'page' : 'btn' }
        if (item.children && item.children.length) {
          handleIconSoltDeep(item.children)
        }
      })
    }
    function checkTree (checkKeys, e) {
      state.pSelect = e.halfCheckedKeys
    }
    function checkBtnItem (e) {
      let flag = e.target.checked
      let value = e.target.value
      if (flag) {
        state.checked.push(value)
        if (state.unChecked.indexOf(value) !== -1) {
          state.unChecked.splice(state.unChecked.indexOf(value), 1)
        }
      } else {
        state.unChecked.push(value)
        if (state.checked.indexOf(value) !== -1) {
          state.checked.splice(state.checked.indexOf(value), 1)
        }
      }
    }
    async function clickTreeNode (e) {
      if (e.length) {
        state.btnListLoading = true
        let { code, data, msg } = await staff.getAuthBtnList({
          mmsFuncId: e[0],
          // 编辑时候 传 “”  其他 传 id
          shopRoleId: state.type === '1' || state.type === '2' ? state.id : ''
        })
        state.checkedList = []
        data.forEach(item => {
          if ((item.chooseFlag && state.unChecked.indexOf(item.mmsFuncId) === -1) || (!item.chooseFlag && state.checked.indexOf(item.mmsFuncId) !== -1)) {
            state.checkedList.push(item.mmsFuncId)
          }
        })
        if (state.checkedList.length === data.length) {
          state.checkAll = true
          state.indeterminate = false
        } else {
          state.checkAll = false
          if (state.checkedList.length) {
            state.indeterminate = true
          } else {
            state.indeterminate = false
          }
        }
        state.btnListLoading = false
        if (code === '00000') {
          state.btnsList = data
        } else {
          root.$message.error(msg || '获取按钮数据失败！')
        }
      } else {
        state.btnsList = []
      }
    }
    function onCheckAllChange (e) {
      state.checkAll = e.target.checked
      if (e.target.checked) {
        state.checkedList = state.btnsList.map(item => {
          return item.mmsFuncId
        })
        state.btnsList.forEach(val => {
          if (state.checked.indexOf(val.mmsFuncId) === -1) {
            state.checked.push(val.mmsFuncId)
          }
        })
      } else {
        state.btnsList.forEach(val => {
          if (state.unChecked.indexOf(val.mmsFuncId) === -1) {
            state.unChecked.push(val.mmsFuncId)
          }
        })
        state.checkedList = []
      }
    }
    function checkBoxChange (e) {
      if (e.length && e.length < state.btnsList.length) {
        state.indeterminate = true
        state.checkAll = false
      } else if (e.length && e.length === state.btnsList.length) {
        state.indeterminate = false
        state.checkAll = true
      } else if (!e.length) {
        state.indeterminate = false
        state.checkAll = false
      }
    }
    return {
      labelCol,
      wrapperCol,
      rules,
      loading,
      treeData,
      replaceFields,
      ...toRefs(state),
      handleSave,
      getRoleData,
      checkTree,
      clickTreeNode,
      handleIconSoltDeep,
      convertTreeListToKeyLeafPairs,
      onCheckAllChange,
      checkBoxChange,
      checkBtnItem
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep textarea {
  resize: none;
}
::v-deep .ant-form-item {
  margin-bottom: 8px;
}
.page-box {
  height: calc( 100vh - 136px );
  overflow: hidden;
}
.auth-box-title {
  // text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;
}
.auth-row-box {
  border: 1px solid #ddd;
  border-radius: 4px;
  height: calc( 100vh - 400px );
  overflow: hidden;
}
.auth-col-box {
  padding: 0 16px;
  height: 100%;
  overflow: auto;
}
.no-data-text {
  color: #666;
  font-size: 14px;
  margin-top: 32px;
  text-align: center;
}
.btn-item {
  padding: 12px 0;
  border-bottom: 1px solid #eee;
  width: 100%;
}
</style>
